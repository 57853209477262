<template>
  <div class="app-container golden-page">
    <el-row class="golden-row">
      <el-col :lg="8" :md="12" :sm="24" :xs="24">
        <div class="golden-content">
          <p class="subtitle">选择账号</p>
          <el-select
              class="accessMoney select-account"
              v-model="fromVal"
              placeholder="姓名/交易账号"
              remote
              :disabled="inputDisabled"
              filterable
              :remote-method="getAccountList"
              :loading="selectLoading"
          >
            <el-option
                v-for="item in fromList"
                :key="item.value"
                :label="item.login"
                :value="item.login">
              <div class="self-option">
                <div class="self-option-left">
                  <div class="alias">{{item.userName}}</div>
                  <div class="login">{{item.login}}</div>
                </div>
                <div class="self-option-right">
                  <account-role
                      :account-info="item"
                  ></account-role>
                </div>
              </div>
            </el-option>
          </el-select>
          <p class="subtitle">选择支付方式</p>
          <div class="pay-tab">
            <div class="pay-tab-header">
              <div class="tab-box">
                <div class="withraw-tab-item"
                     v-for="(item,index) in tabsComputed"
                     :key="index"
                     :class="{on: item.id == currentPaywayId }"
                     @click="tabHandle(item)"
                >{{item.name}}</div>
              </div>
              <span
                  v-if="currentPaywayId == 3"
                  class="buy-link">没有币? <i class="underline"><a :href="buyUsdtUrl" target="_blank">快捷买币</a></i></span>
            </div>
            <div class="tab-content">

              <online-pay
                  v-show="currentPaywayId == 1 && isShowOnline"
                  :current-mt4-info="currentMt4Info"
                  @already-open-enter-money-link="alreadyOpenEnterMoneyLink"
                  @send-online="sendOnlineHandle"
              ></online-pay>
              <telegraphic-pay
                  v-show="currentPaywayId == 2 && isShowTel"

                  :current-mt4-info="currentMt4Info"
                  @already-enter-money-tele="alreadyEnterMoneyTeleHandle"
                  @request-err="teleRequestErrorHandle"
                  @send-tel="sendTelHandle"
              ></telegraphic-pay>
              <usdt-pay
                  v-show="currentPaywayId == 3 && isShowUsdt"
                  :current-mt4-info="currentMt4Info"
                  @already-enter-money-usdt="alreadyEnterMoneyUsdtHandle"
                  @request-err="requestErrorUsdtHandle"
                  @usdr-third-already-jump="alreadyOpenEnterMoneyLink"
                  @send-usdt="sendUsdtHandle"
              ></usdt-pay>
              <otc-pay
                  v-show="currentPaywayId == 10 && isShowOtc"
                  :current-mt4-info="currentMt4Info"
                  @request-err="requestErrorUsdtHandle"
                  @already-open-enter-money-link="alreadyOpenEnterMoneyLink"
                  @send-otc="sendOtcHandle"
              ></otc-pay>
            </div>
          </div>
        </div>
<!--        区分美分账户和美元账户单位,都是后台返回的单位currency todo验证-->
<!--        <div class="sure-btn"
             v-if="currentPaywayId != 3 && currentPaywayId != 10"
             @click="submitHandle"
        >立即入金</div>-->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { queryPayTypeList } from '@/api/bussetup/payManage'
import { mtAccList } from '@/api/cus/depositAction'
import { mapGetters } from 'vuex'
import accountRole from '@/components/common/accountRole'
import onlinePay from '@/components/finance/depositAction/onlinePay'
import otcPay from '@/components/finance/depositAction/otcPay'
import usdtPay from '@/components/finance/depositAction/usdtPay'
import telegraphicPay from '@/components/finance/depositAction/telegraphicTransfer'
import { queryMerchantList } from '@/api/ib/ibMerchantInfo'
export default {
  name:'depositAction',
  components: {
    accountRole,
    usdtPay,
    onlinePay,
    otcPay,
    telegraphicPay,
  },
  data() {
    return {
      loading: false,
      /*新版添加的*/
      isTeleSubmit:  false,
      isUsdtPaySubmit:  false,
      /*tabs:[
        {
          text: 'USDT',
          id: 3
        },
        {
          text: this.$t('payChannel.onlinePayments'),
          id: 1,
        },
        {
          text: this.$t('payChannel.telegraphicTransfer'),
          id: 2,
        },
        {
          text: this.$t('Coin_purchase_payment'),
          id: 10
        },
      ],*/
      tabs: [],
      currentPaywayId: 1,
      fromVal: '',

      leverList: [],
      fromList: [],
      selectLoading: false,
      outMoney: {},
      messageLink: '',
      // 持卡人输入框
      isShowNameOfCardholder: false,
      NameOfCardholder: '',
      /*新支付方式的id*/
      newAccessMoneyPageId: '',
      //电汇
      isTelegraphicUsdTransfer: false,
      // usedt
      isUsdTransfer: false,

      //
      isShowTel: false,
      isShowOnline: false,
      isShowUsdt: false,
      isShowOtc: false,
      buyUsdtUrl: 'https://www.chippay.com'
    }
  },
  created() {
    if(this.depositAccount){
      this.getCustMtaccount(this.depositAccount);
    }

    this.getPayType();
  },
  beforeDestroy(){
    this.$store.commit('setDepositAccount','');
  },
  computed: {
    ...mapGetters(['language','depositAccount']),
    inputDisabled(){
      if(this.depositAccount){
        return true;
      }else {
        return false;
      }
    },
    currentMt4Info(){
      if(this.fromList.length){
        for (let i = 0; i < this.fromList.length; i++) {
          if(this.fromList[i].login == this.fromVal){
            return this.fromList[i];
          }
        }
      }else {
        return {
          currency: '',
        }
      }
    },
    tabsComputed(){
      let copyTabs = this.tabs.slice(0);
      for (let i = copyTabs.length - 1; i >= 0 ; i--) {
        if(!this.isShowTel){
          if(copyTabs[i] && copyTabs[i].id == 2){
            copyTabs.splice(i,1);
          }
        }
        if(!this.isShowOnline){
          if(copyTabs[i] && copyTabs[i].id == 1){
            copyTabs.splice(i,1);
          }
        }
        if(!this.isShowUsdt){
          if(copyTabs[i] && copyTabs[i].id == 3){
            copyTabs.splice(i,1);
          }
        }
        if(!this.isShowOtc){
          if(copyTabs[i] && copyTabs[i].id == 10){
            copyTabs.splice(i,1);
          }
        }
      }
      return copyTabs;
    },
  },
  watch:{
    isHasOnline(newValue){
      if(!newValue){
        this.currentPaywayId = this.tabsComputed[0].id;
      }
    },
    depositAccount(newValue,oldValue){

    }
  },
  methods: {
    getAccountList(query){
      if (query !== '') {
        this.selectLoading = true;
        this.getCustMtaccount(query,() => {
          this.selectLoading = false;
        });
      } else {
        this.fromList = [];
      }
    },
    getPayType(){
      queryPayTypeList({
        oprType: 1,
        status: 0,
      }).then((res) => {
        console.log(res)
        this.tabs = res.content;
        if(this.tabs.length){
          this.selectFirst();
        }
      })
    },
    selectFirst(){
      let isHasOnline = this.tabs.find((item) => {
        return item.id == 1;
      });
      if(!isHasOnline){
        this.currentPaywayId = this.tabs[0].id;
      }
    },
    sendTelHandle(flag){
      this.isShowTel = flag;
    },
    sendOnlineHandle(flag){
      console.log(flag)
      this.isShowOnline = flag ? true: false;
    },
    sendUsdtHandle(flag){
      this.isShowUsdt = flag ? true: false;
    },
    sendOtcHandle(flag){
      this.isShowOtc = flag? true: false;
    },
    // 新加的方法start
    tabHandle(item){
      this.currentPaywayId = item.id;
    },
    // 新加的方法end,custInfo: 包含在账户管理页面: 点击入金传过来的custId
    getCustMtaccount(mt4Info,callback) {
      let parmas = {
        businessType: -1,
        size: 3000,
        page: 0,
      };
      if(mt4Info){
        // 用账户信息查询
        parmas.depositInfo = mt4Info;
      }
      mtAccList(parmas).then((res) => {
        console.log('账户列表',res.content);
        this.fromList = res.content;
        if (res.content) {
          if(this.depositAccount){
            this.fromVal = this.depositAccount;
          }
        }
        callback && callback();
      });
    },
    submitHandle(){
      if(!this.fromVal){
        this.$message.error('请选择需要入金的账户');
        return;
      }
      if(this.currentPaywayId == 2) {
        this.isTeleSubmit = true;
        // ajax submit
      }else if(this.currentPaywayId == 3){
        this.isUsdtPaySubmit = true;
      }
      //
    },
    alreadyOpenEnterMoneyLink(){
      this.showSuccessTips();
    },
    showSuccessTips(){
      this.$message({
        type: 'success',
        message: '已提交等待商家审核'
      })
    },
    alreadyEnterMoneyTeleHandle(){
      this.showSuccessTips();
      this.isTeleSubmit = false;
    },
    teleRequestErrorHandle(){
      this.isTeleSubmit = false;
    },
    alreadyEnterMoneyUsdtHandle(){
      this.isUsdtPaySubmit = false;
    },
    requestErrorUsdtHandle(){
      this.isUsdtPaySubmit = false;
    },
  },
}
</script>
<style lang="less">
.golden-page {
  .select-account {
    width: 100%;
  }
  .select-account {
    margin-bottom: 30px;
  }
  .pay-tips {
    background-color: rgba(216,216,216, .1);
    padding: 20px;
    margin-bottom: 25px;
  }
  .tips {
    background-color: #EEF1F5;
    padding-left: 30px;
    padding-bottom: 30px;
  }
  .online-sure-btn {
    margin-top: 20px;
    /deep/ .el-button--primary {
      background-color: rgb(64, 158, 255);
      border-color: rgb(64, 158, 255);
    }
  }
}
</style>
<style lang="less" scoped>
.el-select-dropdown__list {
  padding-bottom: 10px;
}
.el-select-dropdown__item {
  height: auto;
  line-height: normal;
}
.golden-page {

  .golden-row {
    padding: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #ffffff;
  }
  .title {
    padding-top: 20px;
    padding-left: 30px
  }
  .subtitle {
    margin-bottom: 15px;
    color: #333333;
  }
  .pay-tab {
    border-radius:5px;
    .pay-tab-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .withraw-tab-item {
        cursor: pointer;
      }
    }
    .tab-content {
    }
  }
  .sure-btn {
    width: 116px;
    height:44px;
    line-height: 44px;
    text-align: center;
    margin: 0 auto;
    margin-top: 50px;
    cursor: pointer;
    background: skyblue;
    color: #ffffff;
    border-radius:4px;
  }
  .tips {
    margin-top: 30px;
    padding-top: 20px;
    .tips-title {
      margin-bottom: 10px;
    }
  }
  .buy-link {
    font-size: 12px;
    color: #333333;
  }
}
@media screen and (max-width: 768px){
  .golden-page {
    .golden-row {
      padding-left: 20px;
      padding-right: 20px;
      .tab-box {
        margin-bottom: 5px;
      }
    }
  }
}

@media screen and (min-width:992px){

  .golden-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

}
</style>
