<template>
  <div class="telegraphic-transfer newhome-fd"
    v-loading="loading"
  >
      <div class="pay-tips fs12 font333">
          <div class="title blod mar-bot10">安全交易须知</div>
          <p class="p-text"
             v-for="(item,index) in typeOneList"
             :key="index"
          >{{index + 1}}、{{item}}</p>
      </div>
    <div class="info-box f12 font333">
      <div class="info-item">
        <span class="key-box">Bank name: </span>
        <span class="value-box">{{info.BankName}} <i @click="copyLinkAddress(info.BankName)"
                                                     class="iconfont iconicon_copy"></i></span>
      </div>
      <div class="info-item">
        <span class="key-box">Account Name: </span>
        <span class="value-box">{{info.AccountName}} <i @click="copyLinkAddress(info.AccountName)"
                                                        class="iconfont iconicon_copy"></i></span>
      </div>
      <div class="info-item">
        <span class="key-box">Bank number/account: </span>
        <span class="value-box">{{info.BankNumberAccount}} <i @click="copyLinkAddress(info.BankNumberAccount)"
                                                                class="iconfont iconicon_copy"></i></span>
      </div>
      <div class="info-item">
        <span class="key-box">Bank Address: </span>
        <span class="value-box">{{info.BankAddress}} <i @click="copyLinkAddress(info.BankAddress)"
                                                        class="iconfont iconicon_copy"></i></span>
      </div>
      <div class="info-item">
        <span class="key-box">SWIFT CODE: </span>
        <span class="value-box">{{info.SWIFTCODE}} <i @click="copyLinkAddress(info.SWIFTCODE)"
                                                      class="iconfont iconicon_copy"></i></span>
      </div>
    </div>
      <div class="subtitle mar-bot10 fs14 font333">金额</div>
      <div class="num-input-box pr mar-bot30">
          <el-input class="num-input"
                    :value="curentLimitMoney"
                    :placeholder="`≥${info.minimumLimit}`"
                    @input="inputHandle"
          ></el-input>
          <span class="pa num-prefix">$</span>
      </div>
      <div class="online-sure-btn center" style="margin-top: 30px">
        <el-button
            type="primary"
            :disabled="btnDisabled"
            @click="teleSubmitHandle"
        >立即入金</el-button>
      </div>
  </div>
</template>

<script>
  import { onlyTwoBitFloat,amountInputValidate } from '@/utils/validate';
  import copy from 'copy-to-clipboard';
  import {
    getUSDExRateAndFee,
    EnterMoeny,
  } from '@/api/cus/depositAction';
  import { mapGetters } from 'vuex';
  export default {
    name: 'telegraphicTransfer',
    props:{
      currentMt4Info:{
      },
    },
    data() {
      return {
        typeOneList:[
          '为遵循国际反洗钱公约，任何情况下Maxain都不接受第三方存款或第三方取款；',
          '请严格按照页面显示的银行信息进行转账申请金额，否则可能无法及时到帐；',
          '电汇账户不接受来自于美国制裁国名单的银行或者投资者的汇款，如您不清楚是否属于名单内，请联系客服询问；',
          '上帐资金取决于账户实际到账金额，不收取其他任何费用；',
          '当您完成汇款后，可保存汇款凭证，以免发生异常情况便于核对;',
          '待汇款成功后请联系客服support@maxainasia.com，提供汇款凭证和MT4账户，加快确认周期；'
        ],
        curentLimitMoney: '',
        info: {
          BankName: '',
          AccountName: '',
          BankNumberAccount: '',
          BankAddress: '',
          SWIFTCODE: ''
        },
        loading: false,
      };
    },
    created() {
      this.getChannelList();
    },
    watch:{
    },
    computed: {
      ...mapGetters(['language']),
      btnDisabled(){
        let flag = false;
        if(!this.currentMt4Info || !this.currentMt4Info.login){
          return true;
        }
        if(!onlyTwoBitFloat(this.curentLimitMoney)){
          flag = true;
        }
        if(this.curentLimitMoney === ''){
          flag = true;
        }
        if(this.curentLimitMoney < this.info.minimumLimit){
          flag = true;
        }
        return flag;
      },
    },
    methods: {
      copy,
      teleSubmitHandle(){
        if(this.curentLimitMoney === ''){
          this.$message.error('请输入入金金额');
          this.$emit('request-err');
          return;
        }
        if(!onlyTwoBitFloat(this.curentLimitMoney)){
          this.$message({
            message: '只能输入两位小数金额',
            type: 'error'
          });
          this.$emit('request-err');
          return;
        }
        this.enterGolden();
      },
      inputHandle(val){
        this.curentLimitMoney = amountInputValidate(val);
      },
      getChannelList() {
        getUSDExRateAndFee({
          oprType: 1,
          status: 0,
          payType: 2
        }).then((res) => {
          this.$emit('send-tel', res.length);
          if(res.length){
            this.info = res[0];
          }
        });
      },
      copyLinkAddress(linkAddress) {
        this.copy(linkAddress);
        this.$message({
          message: '复制成功',
          type: 'success'
        });
      },
      enterGolden(){

        if(!this.currentMt4Info.login){
          this.$message.error('请选择账号');
          return;
        }
        let parmas = {
          custId: this.currentMt4Info.id,
          serverId: this.currentMt4Info.serverId,
          login: this.currentMt4Info.login,
          group: this.currentMt4Info.userGroup,
          amount: this.curentLimitMoney,
          billAmount: this.curentLimitMoney + "",
          currency:  'USD',
          payType: this.info.channelCode,
          realExRate: this.info.realExRateDeposit,
          nominalExRate: this.info.nominalExRateDeposit,
          type: 1
        };
        this.loading = true;
        EnterMoeny(parmas).then((res) => {
          this.loading = false;
          this.$emit('already-enter-money-tele')
        }).catch((err) => {
          this.loading = false;
          this.$emit('request-err')
        })
      },
    }
  };
</script>
<style lang="less">
  .pay-tips {
    padding-top: 20px;

    .p-text {
      margin-bottom: 3px;

    }
  }
</style>
<style lang="less" scoped>
  .telegraphic-transfer {
      margin-top: 10px;
    .num-input-box {
      .num-prefix {
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
      }

      /deep/ .el-input__inner {
        padding-left: 30px;
      }
    }

    .info-box {
      padding-bottom: 43px;
    }

    .info-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      .key-box {
        color: #999999;
        font-size: 14px;
      }

      .value-box {
        color: #333333;
        font-size: 14px;
      }

      .iconfont {
        font-size: 20px;
        color: #FE5924;
        margin-left: 10px;
        cursor: pointer;
      }
    }


  }
</style>
