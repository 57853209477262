<template>
    <el-dialog
            :visible="dialogShow"
            title="入金信息确认"
            width="500px"
            :before-close="handleClose"
            class="usdt-third-dialog"
            center
            append-to-body
    >
        <div class="pay-hiddent">
            <channel-desc
                :channel-desc="payInfo.channelDesc"
            ></channel-desc>
            <div class="col-box">
                <span class="col-text">存款金额</span>
                <span class="col-data">{{payInfo.billAmount}} {{payInfo.currency}}</span>
            </div>
            <div v-if="payInfo.fee" class="col-box">
                <span class="col-text">手续费</span>
                <span class="col-data">{{payInfo.fee}} {{payInfo.currency}}</span>
            </div>
            <div v-if="payInfo.billAmount" class="col-box">
                <span class="col-text">上账金额</span>
                <span class="col-data">{{amountText}}</span>
            </div>
            <div class="col-box">
                <span class="col-text">支付币种</span>
                <span class="col-data">{{payInfo.currency}}</span>
            </div>
            <div class="mockbtn  center">
                <el-button
                        type="primary"
                        @click="submitHandle"
                        class="fivepay-submitbtn pointer"
                >确定</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
  export default {
    name: 'usdtThirdDialog',
    props: {
      dialogShow: {
        type: Boolean,
        default: false,
      },
      payInfo: {
        type: Object,
        default() {
          return {
            data: {
              amount: '',
              fee: '',
              billAmount: '',
              currency: '',
            },
            url: ""
          }
        }
      },
      accountCurrency: {
        type: String,
      }
    },
    computed: {
      amountText() {
        if(this.accountCurrency == 'USC'){
          return `${(this.payInfo.amount * 100)} ${this.accountCurrency}`;
        }else {
          return `${this.payInfo.amount} ${this.payInfo.currency}`;
        }
      }
    },
    methods: {
      close(){
        this.$emit('close');
      },
      handleClose() {
        this.close();
      },
      submitHandle(){
        this.$emit('formsubmit');
      }
    }
  };
</script>
<style lang="less">
    .usdt-third-dialog {
        .el-dialog {
            border-radius: 5px;
        }
        .el-dialog__title {
            font-size: 20px;
            color: #333333;
            font-weight: bold;
        }
        .el-dialog__header {
          padding-top: 60px;
        }
    }
</style>
<style lang="less" scoped>
.mockbtn {
    text-align: center;
}
    .usdt-third-dialog {
        .col-box {
            border-bottom: 1px solid #F0F0F0;
            padding-bottom: 8px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            .col-text {
                color: #333333;
            }
            .col-data {
                color: #5D5D5A;
            }
        }
    }
</style>
