import request from '@/utils/request'

/*
*
* 账户列表,可以搜索
* custInfo:
* */
export function mtAccList(data) {//
  return request({
    url: '/crm/getTCustMtaccountsAndCustInfos',
    method: 'get',
    params: data,
  });
}

// 查询渠道
export function queryTPayType
(data){
  return request({
    url: '/crm/fp/tPayType',
    method: 'get',
    params: data,
  });
}

export function getVerifiedInfo(data) {
  return request({
    url: 'crm/getVerifiedInfo',
    method: 'get',
    params: data,
  });
}


export function getUSDExRateAndFee(data) {
  return request({
    url: '/crm/depWith/getUSDExRateAndFee',
    method: 'GET',
    params: data,
  });
}

export function EnterMoeny(data) {//入金
  return request({
    url: '/crm/deposit',
    method: 'POST',
    data,
  });
}
