<template>
    <el-dialog
            :visible="dialogShow"
            title="入金信息确认"
            width="500px"
            :before-close="beforeHandleClose"
            class="onlinepay-dialog"
            center
            append-to-body
    >
        <div class="pay-hiddent">
            <channel-desc :channel-desc="onlinePayInfo.channelDesc"></channel-desc>
            <div class="col-box">
                <span class="col-text">存款金额</span>
                <span class="col-data">{{onlinePayInfo.billAmount}} {{onlinePayInfo.currency}}</span>
            </div>
            <div v-if="onlinePayInfo.billAmount" class="col-box">
                <span class="col-text">上账金额</span>
                <span class="col-data">{{amountText}}</span>
            </div>
            <div class="col-box">
                <span class="col-text">支付币种</span>
                <span class="col-data">{{onlinePayInfo.currency}}</span>
            </div>
            <div
                    @click="submitHandle"
                    class="mockbtn pointer"
            >
                <a
                        class="url-link"
                        :href="onlinePayInfo.url" target="_blank">
                    <el-button

                            class="submit-btn">
                      确定
                    </el-button>
                </a>
            </div>
        </div>
    </el-dialog>
</template>

<script>
  export default {
    name: 'onlinePayDialog',
    props: {
      dialogShow: {
        type: Boolean,
        default: false,
      },
      onlinePayInfo: {
        type: Object,
        default() {
          return {
            amount: "",
            billAmount: "",
            channelName: "",
            currency: "",
            method: "",
            url: ""
          }
        }
      },
      accountCurrency: {
        type: String,
      }
    },
    computed: {
      amountText() {
        if(this.accountCurrency == 'USC'){
          return `${(this.onlinePayInfo.amount * 100)} ${this.accountCurrency}`;
        }else {
          return `${this.onlinePayInfo.amount} USD`;
        }
      }
    },
    methods: {
      close(){
        this.$emit('close');
      },
      beforeHandleClose() {
        this.close();
      },
      submitHandle(){
        this.$emit('formsubmit');
      },
      appInnerSubmitHandle(){
        this.$router.push({name: 'matchUniDeposit',query:{depositUrl: this.onlinePayInfo.url}})
      }
    }
  };
</script>
<style lang="less">
    .onlinepay-dialog {
        .el-dialog {
            border-radius: 5px;
        }
        .el-dialog__title {
            font-size: 20px;
            color: #333333;
            font-weight: bold;
        }
        .el-dialog__header {
          padding-top: 60px;
        }
    }
</style>
<style lang="less" scoped>
.mockbtn {
    text-align: center;
    .submit-btn {
        display: inline-block;
        width: 100%;
        cursor: pointer;
        color: #ffffff;
        background-color: rgb(64, 158, 255);
        border-radius: 5px;
        margin-top: 20px;
    }
}
    .onlinepay-dialog {
        .col-box {
            border-bottom: 1px solid #F0F0F0;
            padding-bottom: 8px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            .col-text {
                color: #333333;
            }
            .col-data {
                color: #5D5D5A;
            }
        }
    }
    .url-link {
        display: block;
    }
</style>
