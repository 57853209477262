import request from '@/utils/request'



// 渠道下列列表
export function queryChannelInfoList(data) {
  return request({
    url: 'crm/channelInfoList',
    method: 'get',
    params: data
  })
}
//新增
export function addPayFee(data) {
  return request({
    url: '/crm/tPayFeeConfig',
    method: 'post',
    data,
  })
}
// 编辑
export function editPayFee(data,type) {
  let url = ''
  if(type){
    url = '/crm/tPayFeeConfig' + '?type=2'
  }else {
    url = '/crm/tPayFeeConfig' + '?type=1'
  }
  return request({
    url,
    method: 'PUT',
    data,
  })
}

// 支付方式列表
export function queryPayTypeList(data,type) {
  return request({
    url: '/crm/tPayType',
    method: 'get',
    params: data,
  })
}


