
<template>
  <div class="otc-pay newhome-fd"
       v-loading="loading"
  >
      <div class="pay-tips fs12 font333">
          <div class="title blod mar-bot10">注意事项</div>
          <p class="p-text"
             v-for="(item,index) in otcTipsList"
             :key="index"
          >{{item}}</p>
      </div>
      <div
              v-if="reason.handleCertIdentifyStatus != 3"
              class="upload-img">
          <p class="upload-title">手持证件照</p>
          <el-upload
                  class="avatar-uploader"
                  action="''"
                  :accept="getLimitUploadImageFormat()"
                  :show-file-list="false"
                  :http-request="uploadIdHand"
                  :before-upload="beforeAvatarUpload"
          >
<!--            :on-success="uploadIdHand"-->
              <img
                      v-if="handleCertImgUrl"
                      :src="handleCertImgUrl"
                      class="avatar middle"
              >
              <div v-else class="avatar-uploader-btn idimg">
                  <i  class="iconfont iconshouchizhengjianzhao"></i>
                  <span class="id-img-desc">上传</span>
              </div>
          </el-upload>
          <uploadImgTips></uploadImgTips>
      </div>
      <div class="usdt-list">
          <div
                  v-for="(item, index) in otcPayInfo"
                  :key="item.channelName"
                  class="usdt pr"
                  :class="{on: item.isSelect  == true}"
                  @click="otcHandle(index,item)"
          >
              <div class="user-box pr">
                  <div class="usdt-top">
                      <div class="usdt-left">
                          <img :src="item.logoUrl"  class="icon-img" alt="">
                          <span class="usdt-name">{{item.channelName}}</span>
                      </div>
                      <div class="usdt-right">
                          <span>限额</span>
                          <span>{{item.limitation}}</span>
                      </div>
                  </div>
                  <div
                          v-if="item.sourceType == 2 && item.isSelect == true"
                          class="spread-box">
                      <img :src="item.qrPic" class="qr-pic" alt="">
                      <div class="address-box">
                          <p class="address-p">{{item.address}}</p>
                          <i
                                  @click="copyLinkAddress(item.address)"
                                  class="iconfont iconcopy"
                          ></i>
                      </div>
                  </div>
                  <i
                          v-if="item.isSelect == true"
                          class="iconfont iconicon_choosed select pa"
                  ></i>
              </div>
          </div>
      </div>
    <div class="subtitle mar-bot10 fs14 font333">金额</div>
      <el-form
              ref="usdtform"
              :model="otcForm"
              :rules="rules"
      >
          <el-form-item prop="curentLimitMoney" class="mar-bot30">
              <div class="num-input-box pr">
                  <el-input
                          class="num-input"
                          :value="otcForm.curentLimitMoney"
                          :placeholder="`≥${minimumLimit}`"
                          @input="inputHandle"
                  ></el-input>
                  <span class="pa num-prefix">USDT</span>
              </div>
          </el-form-item>
      </el-form>
      <div class="usdt-sure-btn center online-sure-btn">
          <el-button
                  type="primary"
                  :disabled="btnDisabled"
                  @click="usdtSubmitHandle"
          >{{butText}}</el-button>
      </div>
      <otc-pay-dialog
              :dialog-show="dialogShow"
              :OtcPayInfo="payInfo"
              @close="innerSubmit"
              @otcSubmit="innerSubmit"
      ></otc-pay-dialog>
  </div>
</template>

<script>
import { uploadFile } from '@/api/data'
import { getBase64,base64toFile } from '@/utils/index'
import { getLimitUploadImageFormat,limitImageSizeFormat } from '@/utils/index'
  import { getVerifiedInfo } from '@/api/cus/depositAction';
  import OtcPayDialog from './OtcPayDialog';
  import { onlyTwoBitFloat,amountInputValidate } from '@/utils/validate';
  import {
    getUSDExRateAndFee,
    EnterMoeny,
  } from '@/api/cus/depositAction';
  import copy from 'copy-to-clipboard';
  import { mapGetters } from 'vuex';
  export default {
    components:{
      OtcPayDialog
    },
    name: 'otcPay',
    props:{
      currentMt4Info:{
      },
    },
    data() {
      let  _this = this;
      let curentLimitMoneyValidate = ((rule,value,callback) => {
        if(value === ''){
          callback(new Error('请输入入金金额'));
        }else if(value < _this.minimumLimit){
          callback(new Error('请输入正确的金额'));
        }else if(!onlyTwoBitFloat(value)){
          callback(new Error('只能输入两位小数金额'));
        }else {
          callback();
        }
      })
      return {
        otcTipsList: [
          '1、请提供本人手持有效证件正面的照片，须露出上半身，且人脸和证件文字必须清晰可见；',
          '2、请务必按照订单显示银行信息转账，否则可能无法到账；',
          '3、您在完成汇款后务必点击“我已完成汇款”按钮。交易员确认收款后，点击“确认”完成存币操作。'
        ],
        reason:{
          handleCertIdentifyStatus: ''
        },
        handleCertImgUrl:'',
        otcForm:{
          curentLimitMoney: ''
        },
        rules:{
          curentLimitMoney:[
            { validator: curentLimitMoneyValidate, trigger: 'blur' },
          ]
        },
        otcPayInfo:[],
        loading: false,
        dialogShow: false,
        payInfo:{
          data: {
            amount: '',
            fee: '',
            billAmount: '',
            currency: '',
          },
          url: "",
        },
        currentUrl: '',
      };
    },
    computed: {
      ...mapGetters(['language',]),
      minimumLimit(){
        if(this.otcPayInfo.length){
          for (let i = 0; i < this.otcPayInfo.length; i++) {
            if(this.otcPayInfo[i].isSelect){
              return this.otcPayInfo[i].minimumLimit;
              break;
            }
          }
        }else {
         return ''
        }
      },
      btnDisabled(){
        let flag = false;
        if(!this.currentMt4Info || !this.currentMt4Info.login){
          return true;
        }
        if(!onlyTwoBitFloat(this.otcForm.curentLimitMoney)){
          flag = true;
        }
        if(this.otcForm.curentLimitMoney === ''){
          flag = true;
        }
        if(this.otcForm.curentLimitMoney < this.minimumLimit){
          flag = true;
        }
        return flag;
      },
      butText(){
        if(this.otcPayInfo.length){
          if(this.getCurrentUsdtWay().sourceType !== 2){
            return '立即入金';
          }else {
            return '已付款';
          }
        }else {
          return ''
        }
      }
    },
    watch:{
      currentMt4Info:{
        handler(newVal){
          if(newVal){
            this.getList();
          }
        },
        deep: true,
      }
    },
    created(){
      this.getChannelList();
      if(this.currentMt4Info.id){
        this.getList();
      }
    },
    methods: {
      getLimitUploadImageFormat,
      getList() {
        getVerifiedInfo({
          custId: this.currentMt4Info.id,
        }).then((res) => {
          this.reason = res;
        })
      },
      uploadIdHand(item){
        getBase64(item.file).then(res => {
          let file = base64toFile(res);
          uploadFile(file).then((res) => {
            this.handleCertImgUrl = res.data.content[0].url;
          });
        });
      },
      // 上传图片的限制
      beforeAvatarUpload(file){
        return limitImageSizeFormat(file,this);
      },

      inputHandle(val){
        this.otcForm.curentLimitMoney = amountInputValidate(val);
      },
      enterGolden(){
        if(!this.currentMt4Info.login){
          this.$message.error('选择账户');
          return;
        }
        let parmas = {
          custId: this.currentMt4Info.id,
          serverId: this.currentMt4Info.serverId,
          login: this.currentMt4Info.login,
          group: this.currentMt4Info.userGroup,
          amount: (this.otcForm.curentLimitMoney / this.getCurrentUsdtWay().nominalExRateDeposit).toFixed(2),
          billAmount: this.otcForm.curentLimitMoney,
          currency:  'USDT',
          payType: this.getCurrentUsdtWay().channelCode,
          realExRate: this.getCurrentUsdtWay().realExRateDeposit,
          nominalExRate: this.getCurrentUsdtWay().nominalExRateDeposit,
          type: 1,
          handleCertImg: this.handleCertImgUrl,
        };
        this.loading = true;
        EnterMoeny(parmas).then((res) => {
          this.loading = false;
          this.payInfo = res.data;
          this.dialogShow = true;
        }).catch((err) => {
          this.loading = false;
          this.$emit('request-err');
        })
      },
      getCurrentUsdtWay(){

        for (let i = 0; i < this.otcPayInfo.length; i++) {
          if(this.otcPayInfo[i].isSelect){
            return this.otcPayInfo[i];
            break;
          }
        }
      },
      getChannelList(){
        getUSDExRateAndFee({
          oprType: 1,
          status: 0,
          payType:10,
        }).then((res) => {
          this.otcPayInfo = res;
          this.$emit('send-otc', res.length);
          if(this.otcPayInfo.length){
            this.addIsSelectProperty();
            this.$set(this.otcPayInfo[0],'isSelect',true);
          }
        })
      },
      addIsSelectProperty(){
        for (let i = 0; i < this.otcPayInfo.length; i++) {
          this.$set(this.otcPayInfo[i],'isSelect',false);
        }
      },
      otcHandle(index){
        this.clearCom();
        this.$set(this.otcPayInfo[index],'isSelect',true);
        this.$refs.usdtform.clearValidate();
      },
      clearCom(){
        for (let i = 0; i < this.otcPayInfo.length; i++) {
          this.$set(this.otcPayInfo[i],'isSelect',false);
        }
      },
      usdtSubmitHandle(){
        if(!this.currentMt4Info){
          this.$message.error('请先选择账号');
          return;
        }
        this.$refs.usdtform.validate((valid) => {
          if(valid){
            if(this.reason.handleCertIdentifyStatus != 3 && !this.handleCertImgUrl){
              this.$message.error('上传手持证件照');
              return;
            }
            this.enterGolden({flag: true});
          }else {
            console.log('no-pass');
          }
        })
      },
      copyLinkAddress(linkAddress) {
        copy(linkAddress);
        this.$message({
          message: '复制成功',
          type: 'success'
        });
      },
      closeHandle(){
        this.dialogShow = false;
      },
      innerSubmit(){
        this.dialogShow = false;
        this.$emit('already-open-enter-money-link');
      },
    }
  };
</script>
<style lang="less" scoped>
.otc-pay {
    margin-top: 10px;
    .last-p {
        margin-bottom: 10px;
    }
    .desc {
        text-indent: 30px;
        margin-bottom: 3px;
        /*padding-left: 20px;*/
    }
    .p-text {
        &.active {
            color: #FE5924;
        }
    }
  .num-input-box {
    .num-prefix {
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
    }
    /deep/ .el-input__inner {
      padding-left: 60px;
    }
  }
  .link-item {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #E6E6E6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:first-of-type {
      margin-bottom: 40px;
    }
    .link-img {
      width: 120px;
      height: 120px;
      padding: 2px;
      border: 1px solid #c5c5c5;
    }
    .link-address {
      width:260px;
      height:56px;
      border-radius:5px;
      border:1px solid rgba(230,230,230,1);
      padding: 8px;
      word-break: break-all;
    }
    .self-btn {
      display: inline-block;
      width:84px;
      height:28px;
      line-height:28px;
      border-radius:4px;
      background-color: #FE5924;
      text-align: center;
      color: #ffffff;
      font-size: 12px;
      margin-top: 20px;
      margin-right: 10px;
      cursor: pointer;
      .down-img {
        text-decoration: none;
        color: #ffffff;
      }
    }
  }
    .subtitle {
        margin-top: 25px;
    }
    .usdt {
        border: 1px solid #e6e6e6;
        &.on {
            border-color: #409eff;
        }
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 10px;
        .user-box {
            padding: 10px 20px;
            .icon-img {
                width: 24px;
                height: 24px;
                margin-right: 10px;
            }
            .usdt-top {
                .usdt-name {
                    vertical-align: middle;
                }
                .usdt-right {
                    font-size: 12px;
                    color: #999999;
                }
            }
            .spread-box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
                margin-bottom: 10px;
                .qr-pic {
                    width: 100px;
                    height: 100px;
                    margin-right: 20px;
                }
                .address-box {
                    flex: 1;
                    height: 100px;
                    padding: 10px;
                    position: relative;
                    background-color: #f9fafb;
                    .address-p {
                        word-break: break-all;
                        font-size: 14px;
                        color: #999999;
                    }
                    .iconcopy {
                        cursor: pointer;
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                    }
                }
            }
        }
        .select {
            font-size: 20px;
            bottom: -2px;
            right: 0;
            color: #409eff;
        }
    }
    .upload-img {
        margin-bottom: 20px;
        .upload-title {
            font-size: 14px;
            color: #333333;
            margin-bottom: 10px;
        }
        .avatar {
            width: 200px;
            height: 100px;
        }
    }
    .avatar-uploader-btn {
        font-size: 14px;
        color: #8c939d;
        width: 200px;
        height: 100px;

        text-align: center;
        background-color: #f5f5f5;
        border: 1px dotted #999;
        &.idimg {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .iconfont {
                font-size: 30px;
            }
            .id-img-desc {
                display: block;
                padding-top: 10px;
            }
            .star {
                color: #E44F47;
            }
        }

    }
}
  @media screen and (min-width: 768px){
      .usdt-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
      }
  }
    @media screen and (max-width: 768px){
        .otc-pay .link-item .link-address {
            width: 60%;
        }
        .usdt-top {
            .usdt-left {
                margin-bottom: 5px;
            }
        }
    }
</style>
