<template>
    <el-dialog
            :visible="dialogShow"
            title="入金信息确认"
            width="500px"
            :before-close="otcPayDialogHandleClose"
            class="fivepay-dialog"
            center
            append-to-body
    >
        <div class="pay-hiddent">
            <channel-desc :channel-desc="OtcPayInfo.channelDesc"></channel-desc>
            <div class="col-box">
                <span class="col-text">存款金额</span>
                <span class="col-data">{{OtcPayInfo.billAmount}} {{OtcPayInfo.currency}}</span>
            </div>
            <div v-if="OtcPayInfo.fee" class="col-box">
                <span class="col-text">手续费</span>
                <span class="col-data">{{OtcPayInfo.fee}} {{OtcPayInfo.currency}}</span>
            </div>
            <div v-if="OtcPayInfo.billAmount" class="col-box">
                <span class="col-text">上账金额</span>
<!--              现在去除了这个渠道 -->
                <span class="col-data">{{OtcPayInfo.amount}}</span>
            </div>
            <div class="col-box">
                <span class="col-text">支付币种</span>
                <span class="col-data">{{OtcPayInfo.currency}}</span>
            </div>
            <div class="mockbtn pointer">
                <input @click="submitHandle" class="fivepay-submitbtn" id="fivepaybtn" value="确定">
            </div>
        </div>
    </el-dialog>
</template>

<script>
  export default {
    name: 'OtcPayDialog',
    props: {
      dialogShow: {
        type: Boolean,
        default: false,
      },
      OtcPayInfo: {
        type: Object,
        default() {
          return {
            amount: "",
            billAmount: "",
            channelName: "",
            currency: "",
            fee: "",
            method: "GET",
            url: ""
          }
        }
      },
    },
    methods: {
      close(){
        this.$emit('close');
      },
      otcPayDialogHandleClose() {
        this.close();
      },
      submitHandle(){
        window.open(this.OtcPayInfo.url,'_blank');
      }
    }
  };
</script>
<style lang="less">
    .fivepay-dialog {
        .el-dialog {
            border-radius: 5px;
        }
        .el-dialog__title {
            font-size: 20px;
            color: #333333;
            font-weight: bold;
        }
        .el-dialog__header {
          padding-top: 60px;
        }
    }
</style>
<style lang="less" scoped>
.mockbtn {
    text-align: center;
    .fivepay-submitbtn {
        display: inline-block;
        width: 100px;
        cursor: pointer;
        height: 30px;
        line-height: 30px;
        color: #ffffff;
        background-color: rgb(64, 158, 255);
        border-radius: 5px;
        margin-top: 20px;
        text-align: center;
    }
}
    .fivepay-dialog {
        .col-box {
            border-bottom: 1px solid #F0F0F0;
            padding-bottom: 8px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            .col-text {
                color: #333333;
            }
            .col-data {
                color: #5D5D5A;
            }
        }
    }
</style>
