<template>

  <div class="online-pay newhome-fd"
    v-loading="loading"
  >
    <ul class="online-list">
      <li class="online-item pr"
          v-for="(item,index) in onlinePayList"
          :key="index"
          :class="{on: item.isSelect == true}"
          @click="payClickHandle(item,index)"
      >
        <div class="item-left">
            <img :src="item.logoUrl" class="icon-img" alt="">
          <span class="fs14">{{item.channelName}}</span>
        </div>
        <div class="item-right fs12">
          <span>限额{{item.limitation}}，</span>
          <span>限时{{item.availableTime}}</span>
        </div>
        <i
          v-if="item.isSelect == true"
          class="iconfont iconicon_choosed select pa"
        ></i>
      </li>
    </ul>
    <div class="sum mar-bot30">
      <div class="subtitle">金额</div>
      <div class="num-input-box">
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
          >
              <el-form-item prop="curentLimitMoney">
                  <el-input
                          class="num-input"
                          :value="form.curentLimitMoney"
                          placeholder="请输入入金金额"
                          @blur="curentMoneyBlurHandle"
                          @input="inputHandle"
                  >
                      <el-select
                              class="el-select-suffix"
                              v-model="currencyType"
                              slot="prepend"
                              placeholder="请选择"
                      >
                          <el-option
                                  v-for="item in currencyList"
                                  :key="item.id"
                                  :label="item.label"
                                  :value="item.id"
                          >
                          </el-option>
                      </el-select>
                  </el-input>
              </el-form-item>
          </el-form>
      </div>
    </div>
    <div
            v-if="currentChannel.payPlatformName !== '5pay-USDT' && currentChannel.payPlatformName !== 'OTC365-USDT'"
            class="total">
      <div class="rate total-item mar-bot10">
        <span class="font333">当前汇率</span>
        <span>$1 = {{currentChannel && currentChannel.sign}}{{exchangeRate || rate}}</span>
      </div>
      <div class="total-money total-item">
        <span class="font333">实付金额</span>
        <span class="total-num">{{getTotalSum}}</span>
      </div>
    </div>
    <div class="online-sure-btn center">
      <el-button
          type="primary"
          :disabled="btnDisabled"
          @click="onlineSubmitHandle"
      >立即入金</el-button>
    </div>
    <otc-pay-dialog
        :dialog-show="OtcPayDialogShow"
        :OtcPayInfo="OtcPayInfo"
        @close="OtcCloseHandle"
        @otcSubmit="otcSubmit"
    ></otc-pay-dialog>
    <online-pay-dialog
        :dialog-show="onlineDialogShow"
        :online-pay-info="onlinePayInfo"
        :account-currency="currentMt4Info.currency"
        @close="onlineCloseHandle"
        @formsubmit="onlineSubmit"
    ></online-pay-dialog>
  </div>
</template>

<script>
  import otcPayDialog from '@/components/finance/depositAction/onlinePayDialog'
  import onlinePayDialog from '@/components/finance/depositAction/onlinePayDialog'
  import { onlyTwoBitFloat,amountInputValidate } from '@/utils/validate';
  import { mapGetters } from 'vuex';
  import {
    getUSDExRateAndFee,
    EnterMoeny,
  } from '@/api/cus/depositAction';
  export default {
    name: 'onlinePay',
    components:{
      otcPayDialog,
      onlinePayDialog,
    },
    props:{
      currentMt4Info:{
      },
    },
    data() {
      var validateMoney = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入入金金额'));
        }else {
          if(!onlyTwoBitFloat(value)){
            callback(new Error('只能输入两位小数金额'));
            this.$emit('request-err');
            return;
          }else {
            callback();
          }
        }
      };
      return {
        bankcardName: '',
        onlinePayList: [],
        form:{
          curentLimitMoney: '',
        },
        rules: {
          curentLimitMoney: [
            {validator: validateMoney, trigger: 'blur'}
          ]
        },
        currencyType: 1,
        loading: false,
        //PAYMENT_URL、NOPAYMENT_URL、PAYMENT_URL_METHOD、NOPAYMENT_URL_METHOD 接口增加4个参数
        perfectmoneyInfo: {
          PAYEE_NAME: '',
          PAYMENT_ID: '',
          PAYEE_ACCOUNT: '',
          PAYMENT_AMOUNT: '',
          PAYMENT_UNITS: '',

          STATUS_URL: '',
          PAYMENT_URL: '',
          NOPAYMENT_URL: '',
          PAYMENT_URL_METHOD: '',
        },
        exchangeRate: '',
        OtcPayDialogShow: false,
        OtcPayInfo:{
          amount: "",
          billAmount: "",
          channelName: "",
          currency: "",
          fee: "",
          method: "GET",
          url: ""
        },
        // 在线支付
        onlineDialogShow: false,
        onlinePayInfo:{
          amount: "",
          billAmount: "",
          channelName: "",
          currency: "",
          method: "",
          url: ""
        }
      };
    },
    watch:{

    },
    computed:{
      ...mapGetters(['language']),
      btnDisabled(){
        let flag = false;
        if(!this.currentMt4Info || !this.currentMt4Info.login){
          return true;
        }
        if(!onlyTwoBitFloat(this.form.curentLimitMoney)){
          flag = true;
        }
        if(this.form.curentLimitMoney === ''){
          flag = true;
        }
        if(this.currentCurrency && this.currentCurrency.minimumLimit){
          if(this.form.curentLimitMoney < this.currentCurrency.minimumLimit){
            flag = true;
          }
        }
        return flag;
      },
      currencyList(){
        let arrTemp = [
          {
            id: 1,
            label: 'USD',
          },
          {
            id: 2,
            label: this.currentChannel && this.currentChannel.currency,
          }
        ];
        // 如果currentCurrency不是人民币,数组就只能选第二个currency,
        if(this.currentCurrency && this.currentCurrency.toLocaleUpperCase() !== 'CNY' && this.currentCurrency.toLocaleUpperCase() !== 'RMB'){
          arrTemp.shift();
          this.currencyType = 2;
        }
        if(this.currentChannel.payPlatformName == '5pay-USDT' || this.currentChannel.payPlatformName == 'OTC365-USDT'){
          arrTemp = [
            {
              id: 1,
              label: 'USDT'
            }
          ];
          this.currencyType = 1;
        }
        return arrTemp;
      },
      currentCurrency(){
        for (let i = 0; i < this.onlinePayList.length; i++) {
          if(this.onlinePayList[i].isSelect){
            return this.onlinePayList[i].currency;
          }
        }
      },
      total(){
        return this.form.curentLimitMoney || 0;
      },
      getTotalSum(){
        if(this.form.curentLimitMoney == '' || !Number(this.form.curentLimitMoney)){
          return `${this.currentChannel.sign}0($0)`;
        }
        if(this.currencyType == 1){
          // 选择USD
          if(this.currentChannel.payPlatformName === 'bft' || this.currentChannel.payPlatformName === 'uEnjoy' || this.currentChannel.payPlatformName === 'BipiPay'){
            return this.currentChannel.sign + Math.ceil(this.form.curentLimitMoney * this.exchangeRate) + `($${this.form.curentLimitMoney})`;
          }else {
            if(this.currentCurrency == 'CNY'){
              // 人民币渠道的都默认向上取整
              return this.currentChannel.sign + Math.ceil(this.form.curentLimitMoney * this.exchangeRate) + `($${this.form.curentLimitMoney})`;
            }else {
              return this.currentChannel.sign + (this.form.curentLimitMoney * this.exchangeRate).toFixed(2) + `($${this.form.curentLimitMoney})`;
            }
          }
        }else {
          //选择CNY
          return this.currentChannel.sign + Math.ceil(this.form.curentLimitMoney) + `($${(this.form.curentLimitMoney / this.exchangeRate).toFixed(2)})`;
        }

      },
      currentChannel(){
        let defaultChannel = {
          availableTime:"",
          channelCode:"",
          channelName:"",
          channelType:1,
          currency:"",
          feeType:1,
          feeValue:0.025,
          isSelect:true,
          limitation:"",
          nominalExRateDeposit:7.1275,
          nominalExRateWithdraw:6.8659,
          oprType:1,
          picType:1,
          realExRateDeposit:7.0395,
          realExRateWithdraw:7.0098,
          sign:""
        };
        for (let i = 0; i < this.onlinePayList.length; i++) {
          if(this.onlinePayList[i].isSelect){
            defaultChannel = this.onlinePayList[i];
            break;
          }
        }
        return defaultChannel;
      },
      rate(){
        return this.currentChannel.nominalExRateDeposit;
      }
    },
    created(){
      this.getChannelList();
    },
    methods: {
      onlineSubmitHandle(){
        this.$refs.form.validate((valid) => {
          if(valid){
            this.enterGolden();
          }else {
            console.log('error');
          }
        })
      },
      OtcCloseHandle(){
        this.OtcPayDialogShow = false;
      },
      otcSubmit(){
        this.OtcPayDialogShow = false;
        this.$emit('already-open-enter-money-link');
      },
      onlineSubmit(){
        this.onlineDialogShow = false;
        this.$emit('already-open-enter-money-link')
      },
      onlineCloseHandle(){
        this.onlineDialogShow = false;
      },

      inputHandle(val){
        this.form.curentLimitMoney = amountInputValidate(val);
      },
      enterGolden(){
        if(this.currentMt4Info.login && !this.currentMt4Info.login ){
          this.$message.error('请选择需要入金的账户');
           return;
        }
        if(!this.form.curentLimitMoney){
          return;
        }
        // 先查询汇率接口在调入金接口
        let finalAmount = this.currencyType == 1 ? (this.form.curentLimitMoney + '') : (((this.form.curentLimitMoney / this.rate).toFixed(2)) + '');
        let finalBillAmount = this.currencyType == 1 ? (((this.form.curentLimitMoney * this.rate).toFixed(2)) + ''): (this.form.curentLimitMoney + '');
        getUSDExRateAndFee({
          oprType: 1,
          payType: 1,
          channelCode: this.currentChannel.channelCode,
          amount: finalAmount,
          billAmount: finalBillAmount,
        }).then((res) => {
          this.exchangeRate = res[0].nominalExRateDeposit;
          let realTimeRealExRateDeposit = res[0].realExRateDeposit;
          let parmas = {
            custId: this.currentMt4Info.id,
            serverId: this.currentMt4Info.serverId,
            login: this.currentMt4Info.login,
            group: this.currentMt4Info.userGroup,
            currency: this.currentChannel.currency ,
            payType: this.currentChannel.channelCode,
            realExRate: realTimeRealExRateDeposit,
            nominalExRate: this.exchangeRate,
            type: 1
          };
          if(this.currencyType == 1){
            parmas.amount = this.form.curentLimitMoney + '';
            if(this.currentChannel.payPlatformName === 'bft' || this.currentChannel.payPlatformName === 'uEnjoy' || this.currentChannel.payPlatformName === 'BipiPay'){
              parmas.billAmount = (Math.ceil(this.form.curentLimitMoney * this.exchangeRate)) + '';
            }else {
              if(this.currentCurrency == 'CNY'){
                // 人民币渠道的都默认取整
                parmas.billAmount = Math.ceil(this.form.curentLimitMoney * this.exchangeRate) + '';
              }else {
                parmas.billAmount = (this.form.curentLimitMoney * this.exchangeRate).toFixed(2) + '';
              }
            }
          }else {
            parmas.amount = ((this.form.curentLimitMoney / this.exchangeRate).toFixed(2)) + '';
            parmas.billAmount = this.form.curentLimitMoney + '';
          }
          this.loading = true;
          EnterMoeny(parmas).then((res) => {
            this.loading = false;
            if(res.channelName.indexOf('OTC365-USDT') > -1 ){
              this.OtcPayInfo = res;
              this.OtcPayDialogShow = true;
            }else {
              // const link = document.createElement('a');
              // link.href = res.data.url;
              // link.target = '_blank';
              // link.style.display = 'none';
              // document.body.appendChild(link);
              // link.click();
              // setTimeout(() => {
              //   document.body.removeChild(link);
              // },1500);
              this.onlineDialogShow = true;
              this.onlinePayInfo = res;

            }
          }).catch((err) => {
            this.loading = false;
          })
        })
      },
      getChannelList(){
        getUSDExRateAndFee({
          oprType: 1,
          payType: 1,
        }).then((res) => {
          this.$emit('send-online', res.length); //
          if(res.length){
            for (let i = 0; i < res.length; i++) {
              if(i == 0){
                res[i].isSelect = true;
              }else {
                res[i].isSelect = false;
              }
            }
          }
          this.onlinePayList = res;
          this.queryExchangeRate();
        })
      },

      clearCom(){
        for (let i = 0; i < this.onlinePayList.length; i++) {
          this.$set(this.onlinePayList[i],'isSelect',false);
        }
      },
      payClickHandle(item,index) {
        this.clearCom();
        this.$set(this.onlinePayList[index],'isSelect',true);
        this.queryExchangeRate();
      },
      queryExchangeRate(){
        let params = {
          oprType: 1,
          payType: 1,
          channelCode: this.currentChannel.channelCode,
        }
        if(this.form.curentLimitMoney){
          let finalAmount = this.currencyType == 1 ? (this.form.curentLimitMoney + '') : (((this.form.curentLimitMoney / this.rate).toFixed(2)) + '');
          let finalBillAmount = this.currencyType == 1 ? (((this.form.curentLimitMoney * this.rate).toFixed(2)) + ''): (this.form.curentLimitMoney + '');
          params.amount = finalAmount;
          params.billAmount = finalBillAmount;
        }
        getUSDExRateAndFee(params).then((res) => {
          this.exchangeRate = res[0].nominalExRateDeposit;
        })
      },
      curentMoneyBlurHandle(){
        this.queryExchangeRate();
      },
    }
  };
</script>
<style lang="less" scoped>
  .online-pay {
    margin-top: 25px;
    .online-list {
      padding: 0;
      margin-bottom: 40px;
      .online-item {
        cursor: pointer;
        position: relative;
        padding: 10px 20px;
        border-radius:4px;
        border:1px solid rgba(230,230,230,1);
        margin-bottom: 10px;
        .icon-img {
          width: 20px;
            max-width: 30px;
            max-height: 30px;
          vertical-align: middle;
          margin-right: 5px;
        }
        &.on {
          border-color: rgb(64, 158, 255);
        }
        .select {
          font-size: 20px;
          bottom: -2px;
          right: 0;
          color: rgb(64, 158, 255);
        }
          .item-right {
              color: #999999;
          }
      }
    }
    .subtitle {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .el-select-suffix {
      width: 90px;
    }
    .total {
      height:88px;
      background:rgba(248,248,248,1);
      border-radius:4px;
      padding-top: 20px;
      padding-bottom: 20px;
      .total-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        .total-num {
          color: #FE5924;
        }
      }
    }
    .perfectmoney-dialog {
          .mockbtn {
              width: 150px;
              height: 40px;
              margin: 0 auto;
              line-height: 40px;
              text-align: center;
              color: #FFFFFF;
              margin-top: 20px;
              background-color: #FE5924;
              position: relative;
              .perfectmoney-submitbtn {
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                  position: absolute;
                  z-index: 99;
              }
          }
      }
  }
    @media screen  and (min-width: 768px){
        .online-pay .online-list .online-item {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            align-content: flex-start;
            .icon-img {
                margin-bottom: 5px;
            }
        }
    }
    @media screen and (max-width: 768px){
        .online-pay .online-list .online-item .item-left {
            margin-bottom: 5px;
        }
    }

</style>
